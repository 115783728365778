Rivers.OffCanvas = function(options) {
	'use strict';
	
	var $ = jQuery,
		
		defaultOptions = {
			sourceUl: null, //ul to copy into the created off canvas menu
			detachSourceUl: false, // detaches the sourceUl from the dom (destroys any existing menus)
			attachTo: 'body', //element to attach the menu element to
			shiftElements: null, //elements to shift out of the way to reveal the menu
			attachToOpenClass: 'offcanvas-shift--opened', //class to apply to all shiftable elements and the attachTo class when the menu is opened
			attachToCloseClass: 'offcanvas-shift--closed', //class to apply to all shiftable elements and the attachTo class when the menu is closed
			themeClass: '', // "theme" class / classes to apply to attachToOpen/Close class + off canvas menu itself
			menuClassDefault: 'offcanvas', //class name given to the created off canvas menu div
			force3dTransforms: false, // forces inner-menu navigation to use translate3d even without modernizr being used (if you don't mind breaking ie9)
			updateSubmenuOffsetOnResize: 50, // resizing the window recalculates the offset of submenus every 50ms, false will disable recalculations
			offCanvasToggle: '.offcanvas-toggle',
			menuWrapCallback: function(offCanvasInstance, clonedUl) { // wrap the ul in another structure, you must return the ul back
				// This is an example or wrapping menus in additional structure. clonedUl is an html string at this point, you'll need to cast it with $() to manipulate it easily
				// clonedUl = $(clonedUl).addClass('ul-root'); // this is done automatically for you (finds the first ul and makes it ul-root)
				// clonedUl = $('<b class="ul-root">').append(clonedUl); // or we can wrap our menu in a bold tag and explicitly define that as the ul-root (hmm);
				
				return clonedUl; // whatever you return will be used as the menu. use with care.
			},
			closeMenuOnOuterClick: true,
			linkParentInChild: true, //adds a link to the parent li's <a> tag (if one exists) to allow navigating nested uls without triggering parent <a> tags
			prependBackButton: true, //prepends a back button to each level
			innerShiftMethod: 'doShiftingLeftToRight', //shift the ul on each clicked menu item
		};
	
	options = $.extend({}, defaultOptions, options);
	
	var attachTo = $(options.attachTo),
		sourceUl = $(options.sourceUl),
		shiftElements = $(options.shiftElements),
		_this = this,
		menu = null,
		events = new Rivers.Events(_this),
		themeClass = options.themeClass,
		openClass = options.attachToOpenClass,
		closeClass = options.attachToCloseClass,
		innerShiftMethodInstance = {collapseSubmenu: function() {}, openSubmenu: function() {} }, //new options.menuShiftMethod instance
		outermostContainer = $(), // this includes any structure returned from menuWrapCallback
		ulRootClassName = 'ul-root', //this is the name given to the container just above your main ul data (because we might be wrapping menus in a lot of divs). you can explicitly define this class name in your returned menu otherwise the system will guess what the root is.
		ulRootClass = '.'+ulRootClassName,
		ulRoot = $(); //points to the ul root container
	
	var linkParentInChild = function(ul) {
		//check to see if the parent li has a clickable <a> tag, append that link to the top of the nested submenu
		
		ulRoot.find('li > a + ul').each(function() {
			
			var parentLi = $(this).parent('li'),
				parentLink = parentLi.find('> a');
			
			//parentLink.on('click', innerShiftMethodInstance.openSubmenu);
			
			var menuEntry = $('<li class="offcanvas-parent-link">').append(parentLink.clone());
			
			$(this).prepend(menuEntry);
			
		});
		
	};
	
	var prependBackButton = function(ul) {
		ul.find('ul').each(function() {
			var goBack = $('<li class="offcanvas-nav-back">').append('<a>Back</a>');
			
			goBack.on('click', function(e) {
				innerShiftMethodInstance.collapseSubmenu();
				e.preventDefault();
			});
			
			$(this).prepend(goBack);
		});
		
	};
	
	_this.offCanvasToggleButton = {
		open: function() { }, // the most basic structure of the toggle button callbacks
		close: function() { }
	};
	
	// these are your functionality "themes", they define openSubmenu & collapseSubmenu
	
	_this.doShiftingAccordion = function(ul) {
		
	};
	
	_this.doShiftingLeftToRight = function(ul) { //attach event listeners that shift the menu as nested uls are navigated to
		
		var nestedUlTargets = ulRoot.find('li > a + ul, li > span + ul').prev('a, span'), //targeting the li's themselves will fire them for every submenu clicked, adjacent is the way to go
			nestedUlParents = nestedUlTargets.parent('li'),
			numberOfOpenSubmenus = 0,
			__this = this;
		
		var updateUlShiftPosition = function() {
			var menuRootUlWidth = ulRoot.outerWidth(),
				leftOffset = -numberOfOpenSubmenus * menuRootUlWidth;
			
			if(Modernizr.csstransforms3d || options.force3dTransforms) {
				ulRoot.css('transform', 'translate3d('+leftOffset+'px, 0, 0)');
			} else {
				ulRoot.css('left', leftOffset);
			}
			
			events.fire('submenu.shift');
		};
		
		(function recalculateSubmenuOffsetOnResize() {
			var rate = options.updateSubmenuOffsetOnResize;
			
			if(rate !== false) {
				$(window).on('resize', Rivers.throttle(updateUlShiftPosition, rate));
			}
		})();
		
		__this.collapseSubmenu = function(count) {
			
			if(count === undefined) count = 1;
			
			numberOfOpenSubmenus -= count;
			if(numberOfOpenSubmenus < 0) {
				numberOfOpenSubmenus = 0;
				
				_this.close();
			} else {
				events.fire('submenu.close'); // we only fire submenu.close if there's going to be a shift animation
			}
			
			updateUlShiftPosition();
		};
		
		__this.openSubmenu = function(e, ___this) { //if you're reading this im so sorry about all the ___thisis
			e.preventDefault(); // make sure links don't fire
			
			console.log('opening submenu');
			
			if(this instanceof HTMLElement) {
				___this = $(this);
			} else if(___this === undefined) {
				throw "Need to pass in the jQuery object that triggers the opened submenu";
			} else {
				___this = $(___this); //if you supplied a target use submenu element instead
			}
			
			nestedUlParents.removeClass('open'); //"close" all open menus and open ancestors of the currently opened menu
			numberOfOpenSubmenus = ___this.parents(ulRootClass + ' li').addClass('open').length; //get the count of all parent li's underneath the root class name
			___this.addClass('open');
			
			var submenuHeight = ___this.find('ul').first().outerHeight(true);
			
			ulRoot.css('minHeight', submenuHeight); // adjust height of ul to account for the submenus using position absolute
			
			numberOfOpenSubmenus += 1;
			
			menu.animate({scrollTop: 0}, 300); //ensure if we're at the bottom of scrolled menu content, child menu is always moves into view
        	
			events.fire('submenu.open');
			updateUlShiftPosition(); // count the number of opened submenus and shift the ul container by that amount
		};
		
		nestedUlTargets.on('click', function(e) {
			__this.openSubmenu(e, $(this).parent('li'));
		});
	};
	
	_this.element = function() {
		return outermostContainer;
	};
	
	_this.on = function(label, callback) {
		events.on(label, callback);
		return _this;
	};
	
	_this.open = function(fireCallbacks) {
		
		if(fireCallbacks === undefined) fireCallbacks = true;
		
		if(!menu.hasClass(openClass)) { //only fire the close callback if the menu was open
		 	events.fire('open');
		}
		
		shiftElements.addClass(openClass + ' ' + themeClass).removeClass(closeClass);
		menu.addClass(openClass + ' ' + themeClass).removeClass(closeClass);
		
		_this.offCanvasToggleButton.open(); //update any assigned button
		
		return 'opened';
	};
	
	_this.close = function(fireCallbacks) {
		
		if(fireCallbacks === undefined) fireCallbacks = true;
		
		if(menu.hasClass(openClass)) { //only fire the close callback if the menu was open
			events.fire('close');
		}
		
		shiftElements.removeClass(openClass).addClass(closeClass + ' ' + themeClass);
		menu.removeClass(openClass).addClass(closeClass + ' ' + themeClass);
		
		_this.offCanvasToggleButton.close(); //update any assigned button
		
		return 'closed';
	};
	
	_this.toggle = function() {
		return menu.hasClass(openClass) ? _this.close() : _this.open();
	};
	
	_this.getClonedUl = function(ul) { //does not clone event handlers, only structure!!
		return $($(ul)[0].outerHTML); // typecast ul to jquery object just for kicks
	};
	
	var init = function() {
		
		if(!sourceUl.length) {
			throw "sourceUl is a required selector string or jQuery object";
		}
		
		if(!attachTo.length) {
			throw "attachTo is a required selector string or jQuery object";
		}
		
		menu = $('<div>').addClass(themeClass + ' ' + options.menuClassDefault); // outermost menu container
		
		var clonedUl;
		
		if(options.detachSourceUl) {
			clonedUl = $(sourceUl).detach();
		} else {
			clonedUl = _this.getClonedUl(sourceUl);
		}
		
		if(options.menuWrapCallback) { // this lets us wrap our menu in another div for more control over the structure
			
			var callbackReturnedMenu = options.menuWrapCallback(_this, clonedUl); //accepts this instance + the passed ul
			
			if(!callbackReturnedMenu.length) {
				throw "menuWrapCallback needs to return a jQuery object or an html string";
			}
			
			menu.append(callbackReturnedMenu);
			
		} else {
			clonedUl.appendTo(menu);
		}
		
		menu = $('<div>').append(menu); // we wrap our junk in a div (temporary)
		ulRoot = menu.find(ulRootClass).first(); // helper to point below functions to the meat of the ul
		
		if(!ulRoot.length) {
			ulRoot = menu.find('ul:first').addClass(ulRootClassName); // if there's no designated classname to use as the root find the first ul and use it's parent as the root
		}
		
		if(options.innerShiftMethod) {
			// initialize the shifting method for this menu. shifting methods can be passed into the constructor to
			// add more functionality over the builtin 'doShiftingLeftToRight'
			
			if(typeof options.innerShiftMethod === 'string') {
				innerShiftMethodInstance = new _this[options.innerShiftMethod](menu);
			} else {
				innerShiftMethodInstance = new options.innerShiftMethod(menu);
			}
		}
		
		if(options.linkParentInChild) {
			linkParentInChild(menu); // disable any links on submenu parent elements so they dont fire when clicked- add the parent link into the top of the submenu
		}
		
		if(options.prependBackButton) {
			prependBackButton(menu); //prepend a back button onto all the submenus
		}
		
		
		if(options.offCanvasToggle) {
			
			var OffCanvasButton = function(offCanvasButton) {
				
				var offCanvasIconActiveClass = 'is-active',
					offCanvasButtonInstance = this;
				
				offCanvasButtonInstance.instance = function() {
					return offCanvasButton;
				};
				
				offCanvasButtonInstance.open = function() {
					offCanvasButton.addClass(offCanvasIconActiveClass);
				};
				
				offCanvasButtonInstance.close = function() {
					offCanvasButton.removeClass(offCanvasIconActiveClass);
				};
				
				offCanvasButton.on('click', _this.toggle);
			};
			
			_this.offCanvasToggleButton = new OffCanvasButton(
				$(options.offCanvasToggle)
			);
		}
		
		outermostContainer = menu.find('> *');
		
		if(options.closeMenuOnOuterClick) {
			var collapseMenuIfNot = $()
				.add(_this.offCanvasToggleButton.instance())
				.add(outermostContainer);
			
			var checkEventSource = function(e) {
				
				if(collapseMenuIfNot.is(e.target) === false && //didn't click the collapse if not target
					collapseMenuIfNot.has(e.target).length === 0) { //didn't click a descendent of the if not target
					
					_this.close();
				}
			};
			
			$('body').click(checkEventSource);
		}
		
		attachTo.append(outermostContainer); //drop our menu onto the page without losing our event handlers
		
		_this.close(false); //ensure all elements have the closed class applied before we attach our menu
	};
	
	init();
};
